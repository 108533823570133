/* Variables */
:root {
    --cmx-base-color: hsl(0deg 0% 0%);
    --cmx-foreground-color: hsl(313.88deg 100% 4.8%);
    --cmx-text-color: hsl(0deg 0% 49.75%);
    --cmx-detail-color: hsl(0deg 0% 0%);
    --cmx-accent-color: hsl(0deg 0% 76.53%);
    --cmx-base-shadow: color-mix(in hsl, var(--cmx-base-color) 10%, hsl(0, 0%, 1%));
    --cmx-foreground-shadow: color-mix(in hsl, var(--cmx-foreground-color) 10%, hsl(0, 0%, 1%));
    --cmx-text-shadow: color-mix(in hsl, var(--cmx-text-color) 10%, hsl(0, 0%, 1%));
    --cmx-detail-shadow: color-mix(in hsl, var(--cmx-detail-color) 90%, hsl(0, 0%, 1%));
    --cmx-accent-shadow: color-mix(in hsl, var(--cmx-accent-color) 10%, hsl(0, 0%, 1%));

    --cmx-content-width: 1100px;

    --cmx-container-background: radial-gradient(ellipse at bottom, color-mix(in hsl, var(--cmx-foreground-color) 100%, transparent), transparent),
        radial-gradient(ellipse at top, color-mix(in hsl, var(--cmx-detail-color) 10%, transparent), transparent),
        color-mix(in hsl, var(--cmx-foreground-shadow) 80%, transparent);
    --cmx-container-background-reverse: radial-gradient(ellipse at bottom, color-mix(in hsl, var(--cmx-detail-color) 10%, transparent), transparent),
        radial-gradient(ellipse at top, color-mix(in hsl, var(--cmx-foreground-color) 100%, transparent), transparent),
        color-mix(in hsl, var(--cmx-foreground-shadow) 80%, transparent);
    --cmx-container-box-shadow: 0px 0px 20px 1px var(--cmx-detail-shadow);
    --cmx-container-border: 1px solid var(--cmx-detail-color);

    --cmx-font-tiny: 16px;
    --cmx-font-small: 20px;
    --cmx-font-medium: 25px;
    --cmx-font-big: 32px;
}

/* Background */
body {
    display: flex;
    flex-direction: column;
    margin: 0;
    background: var(--cmx-base-color);
}

/* Fonts */
body {
    color: var(--cmx-text-color);
    font-family: "Delius", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: var(--cmx-font-small);
}

.noscroll {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "Rosarivo", cursive;
    font-weight: 400;
    font-style: normal;
    color: var(--cmx-accent-color);
}

#root {
    display: flex;
    flex-direction: column;
}

.container-horizontal {
    display: flex;
    flex-direction: row;
    row-gap: 20px;
    column-gap: 20px;
}

.container-vertical {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    column-gap: 20px;
}

.container-style {
    background: var(--cmx-container-background);
    box-shadow: var(--cmx-container-box-shadow);
    border: var(--cmx-container-border);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
}

a {
    color: var(--cmx-accent-color);
    text-decoration: none;
    user-select: none;
}

.linkless-link {
    cursor: pointer;
    color: var(--cmx-accent-color);
    text-decoration: none;
    user-select: none;
}

.gray-placeholder {
    background: gray;
    width: 100%;
    height: 100%;
}

a:hover,
.linkless-link:hover {
    color: #ffffff;
    text-decoration: none;
    text-shadow: 0px 0px 20px var(--cmx-accent-color);
    transition: all .1s;
}

.masked-left-right {
    --mask: linear-gradient(to right, transparent 1%, black 2%, black 98%, transparent 99%);
    -webkit-mask-image: var(--mask);
    mask-image: var(--mask);
}

.overlayed-left-right {
    background: linear-gradient(to right, var(--cmx-base-color) 1%, transparent 5%, transparent 95%, var(--cmx-base-color) 99%);
}