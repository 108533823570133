.character-item {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.character-item a {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    row-gap: 15px;
}

.character-name {
    text-align: center;
    font-family: "Rosarivo", cursive;
    font-weight: 400;
    font-style: normal;
    display: flex;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
}

.character-thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}

.character-thumbnail-large {
    width: 300px;
    height: 300px;
}

.character-thumbnail-medium {
    width: 180px;
    height: 180px;
}

.character-thumbnail-small {
    width: 120px;
    height: 120px;
}

.character-thumbnail img {
    width: 100%;
    height: 100%;
}