.comic-selector {
    width: 100%;
}

.comic-selector-comic {
    background: var(--cmx-container-background);
    box-shadow: var(--cmx-container-box-shadow);
    border: var(--cmx-container-border);
    text-align: center;
    width: 100%;
    height: 500px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    align-items: center;
}

.comic-selector-navigation {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    z-index: 1;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.comic-selector-image {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}

.comic-selector-nav-wrapper {
    width: 30%;
    height: 100%;
}

.comic-selector-nav {
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    visibility: hidden;
    font-size: var(--cmx-font-medium);
}

.comic-selector-image img {
    height: 100%;
}

.comic-selector-nav-wrapper:hover .comic-selector-nav {
    visibility: visible
}

.comic-name {
    z-index: 1;
    margin-top: 10px;
    max-width: 150px;
    max-height: 150px;
    overflow: hidden;
}

.comic-name img {
    width: 100%;
    filter: drop-shadow(0px 0px 3px #000) drop-shadow(0px 1px 2px #000);
}

@media screen and (max-width: 768px) {
    .comic-selector.container-horizontal {
        flex-direction: column;
        row-gap: 20px;
    }
}