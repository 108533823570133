.page-wrapper {
    width: 100%
}

.page-item {
    background: var(--cmx-container-background);
    box-shadow: var(--cmx-container-box-shadow);
    border: var(--cmx-container-border);
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    min-height: 1000px;
}