#the-comic {
    z-index: 1;
    position: relative;
}

.the-comic-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    align-items: center;
}

.the-comic-link {
    display: block;
}

#the-comic img {
    margin: 0;
    padding: 0;
    max-width: 100%;
    height: auto;
    text-align: center;
    box-shadow: 0 0 50px 20px rgba(0, 0, 0, 1);
}

.content-warning-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: hsla(0, 0%, 0%, 0.8);
    font-size: var(--cmx-font-medium);
    text-align: center;
    display: flex;
    row-gap: 30px;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px;
    box-sizing: border-box;
    flex-wrap: wrap;
    z-index: 2;
    backdrop-filter: blur(20px);
}

.comic-loading-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: hsla(0, 0%, 0%, 0.1);
    font-size: var(--cmx-font-medium);
    text-align: center;
    display: flex;
    row-gap: 30px;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px;
    box-sizing: border-box;
    flex-wrap: wrap;
    z-index: 2;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.content-warning-buttons button {
    appearance: button;
    background-color: hsl(235, 17%, 30%);
    border: 1px solid hsl(249, 30%, 5%);
    border-radius: 4px;
    box-sizing: border-box;
    color: hsl(0, 0%, 100%);
    cursor: pointer;
    font-family: Graphik, -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: var(--cmx-font-small);
    line-height: 1.15;
    overflow: visible;
    padding: 12px 16px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: all 80ms ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
}

.content-warning-buttons button:hover {
    background-color: hsl(235, 25%, 48%);
    border-color: hsl(249, 48%, 23%);
}

.comic-navigator {
    justify-content: center;
    align-items: center;
    transition: all linear 0.3s;
    padding: 10px;
    overflow: hidden;
    flex-wrap: wrap;
    align-content: center;
    font-size: var(--cmx-font-medium);
    width: 80%;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.comic-navigator-item {
    display: flex;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
}

.comic-title-wrapper {
    padding: 10px;
    z-index: 2;
    font-size: var(--cmx-font-medium);
    width: 80%;
}

.comic-title {
    text-align: center;
    font-weight: bold;
    color: var(--cmx-accent-color);
    font-family: "Rosarivo", cursive;
    font-weight: 400;
    font-style: normal;
}

.comic-character-list {
    width: 80%;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
}

.comic-character-list .character-thumbnail-small {
    width: 100px;
    height: 100px;
}

.navigator-top {
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
}

.navigator-bottom {
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .comic-navigator {
        font-size: var(--cmx-font-big);
        column-gap: 50px;
    }

    .comic-navigator-text {
        display: none;
    }

    .comic-character-list .character-thumbnail-small {
        width: 75px;
        height: 75px;
    }
}

@media screen and (min-width: 1025px) {
    .comic-navigator {
        column-gap: 75px;
    }
}

@media screen and (min-width: 1201px) {
    .comic-navigator {
        column-gap: 100px;
    }
}