footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-top: 50px;
    width: 100%;
    padding: 15px;
    border-top: var(--cmx-container-border);
    background: var(--cmx-container-background);
    box-shadow: var(--cmx-container-box-shadow);
}

.footer-link-collection {
    width: 100%;
    justify-content: space-evenly;
    max-width: var(--cmx-content-width);
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

footer .menu-item-no-dropdown,
footer .menu-item-no-dropdown-submenu .menu-item-no-dropdown {
    font-size: var(--cmx-font-small);
}

footer .menu-item-no-dropdown-submenu {
    row-gap: 20px;
    margin-left: 20px;
    margin-top: 10px;
}

.footer-social {
    flex-wrap: wrap;
    column-gap: 0;
}

footer .menu-item-social {
    width: 50%;
}

.navigation-bottom.container-vertical {
    flex: 1;
}

.social-media.container-vertical {
    flex: 1;
    flex-grow: 2;
}

.related.container-vertical {
    flex: 1;
}

@media screen and (max-width: 768px) {
    .footer-link-collection {
        flex-direction: column;
        row-gap: 20px;
    }

    .navigation-bottom.container-vertical,
    .social-media.container-vertical,
    .related.container-vertical {
        align-items: center;
        text-align: center;
    }

    footer .menu-item-no-dropdown-submenu {
        margin-left: 0px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    footer .menu-item-no-dropdown,
    footer .menu-item-social,
    footer .menu-item-no-dropdown-submenu .menu-item-no-dropdown {
        font-size: var(--cmx-font-medium);
    }
}