.archive-comic-item,
.character-comic-item {
    cursor: pointer;
    display: inline-block;
    border-style: solid;
    border-width: 5px;
    border-color: #333;
    position: relative;
}

.archive-comic-item.non-hovered,
.character-comic-item.non-hovered {
    transition-timing-function: ease;
    transition-duration: 1000ms;
}

.archive-comic-item-thumbnail {
    width: 120px;
    height: 120px;
}

.character-comic-item-thumbnail {
    width: 120px;
    height: 60px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.archive-comic-item-thumbnail img {
    width: 100%;
    height: 100%;
}

.character-comic-item-thumbnail img {
    width: 100%;
}

.archive-comic-item-name-wrapper,
.character-comic-item-name-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .5);
}

.archive-comic-item-name-wrapper.non-hovered,
.character-comic-item-name-wrapper.non-hovered {
    transition-timing-function: ease;
    transition-duration: 1000ms;
}

.archive-comic-item-name,
.character-comic-item-name {
    padding: 5px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
    font-size: 16px;
    line-height: 25px;
    font-weight: bold;
    visibility: hidden;
    opacity: 0;
}

.archive-comic-item-name.non-hovered,
.character-comic-item-name.non-hovered {
    transition-timing-function: ease;
    transition-duration: 1000ms;
}

.archive-comic-item-number-wrapper {
    width: 100%;
    position: absolute;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 0;
}

.archive-comic-item-number {
    background: #37393a;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .5);
    pointer-events: none;
    width: 30px;
    height: 25px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
}

.archive-comic-item-wrapper.hovered .archive-comic-item,
.character-comic-item-wrapper.hovered .character-comic-item {
    transform: scale(1.3);
    z-index: 10;
    transition-timing-function: ease;
    transition-duration: .5s;
}

.archive-comic-item-wrapper.hovered .archive-comic-item-name-wrapper,
.character-comic-item-wrapper.hovered .character-comic-item-name-wrapper {
    background-color: rgba(0, 0, 0, 0.65);
    transition-timing-function: ease;
    transition-duration: .3s;
}

.archive-comic-item-wrapper.hovered .archive-comic-item-name-wrapper .archive-comic-item-name,
.character-comic-item-wrapper.hovered .character-comic-item-name-wrapper .character-comic-item-name {
    visibility: visible;
    opacity: 1;
    transition-timing-function: ease;
    transition-duration: .3s;
}