.main-page,
.page-single,
.comic-page,
.post-single,
.character-single {
    max-width: var(--cmx-content-width);
    align-items: center;
    width: 100%;
    position: relative;
    transition: all linear 0.3s;
}

.content-wrapper {
    align-items: center;
    padding: 1px;
    min-height: 1000px;
    overflow: hidden;
}

.main-content {
    width: 65%;
}

.sidebar-content {
    width: 35%;
}

@media screen and (max-width: 768px) {
    .main-content-wrapper.container-horizontal {
        flex-direction: column;
        row-gap: 20px;
    }

    .main-content {
        width: 100%;
    }

    .sidebar-content {
        width: 100%;
    }
}