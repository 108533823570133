:root {
    --fader-speed: 300ms;
}

.fader-appear, .fader-enter {
    opacity: 0!important;
    visibility: hidden!important;
}

.fader-appear-active, .fader-enter-active {
    opacity: 1!important;
    visibility: visible!important;
    transition: opacity var(--fader-speed) ease-in-out!important;
}

.fader-appear-done, .fader-enter-done {
    opacity: 1!important;
    visibility: visible!important;
}

.fader-exit {
    opacity: 1!important;
    visibility: visible!important;
}

.fader-exit-active {
    opacity: 0!important;
    transition: opacity var(--fader-speed) ease-in-out!important;
}

.fader-exit-done {
    opacity: 0!important;
    visibility: hidden!important;
}