.navigation-bar-bars {
    display: none;
    visibility: hidden;
    opacity: 0;
    width: 30px;
    height: 30px;
    font-size: var(--cmx-font-medium);
    padding: 6px;
    color: var(--cmx-accent-color);
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
}

.navbar {
    align-items: center;
    max-width: var(--cmx-content-width);
    visibility: visible;
}

.menu {
    text-align: center;
    column-gap: 20px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0px;
    transition: all linear 0.3s;
    align-items: center;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.menu-item {
    list-style: none;
    font-size: var(--cmx-font-medium);
}

.menu-item a {
    cursor: pointer;

    &.active {
        color: #000000;
    }
}

.dropdown-menu {
    padding: 15px;
    position: absolute;
    z-index: 3;
    background: var(--cmx-foreground-color);
    box-shadow: var(--cmx-container-box-shadow);
    border: var(--cmx-container-border);
}

.overlay-navbar-wrapper {
    position: absolute;
}

.overlay-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    visibility: hidden;
    opacity: 0;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
    background: hsla(0, 0%, 0%, 0.71);
    backdrop-filter: blur(5px);
    text-align: center;
    row-gap: 50px;
    padding: 30px;
    box-sizing: border-box;
    overflow: scroll;
}

.overlay-navigation-bar-bars {
    width: 30px;
    height: 30px;
    font-size: var(--cmx-font-big);
    padding: 6px;
    color: var(--cmx-accent-color);
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
}

.overlay-navbar-social {
    column-gap: 40px;
    row-gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
    font-size: var(--cmx-font-big);
}

.menu-item-no-dropdown {
    font-size: var(--cmx-font-big);
}

.menu-item-no-dropdown-submenu {
    margin-top: 40px;
    row-gap: 60px;
}

.menu-item-no-dropdown-submenu .menu-item-no-dropdown {
    font-size: var(--cmx-font-medium);
}

.menu-item-social-title {
    margin-left: 10px;
}

header .menu-item-social {
    line-height: 0px;
}

@media screen and (max-width: 768px) {
    .navigation-bar-bars {
        display: block;
        visibility: visible;
        opacity: 1;
    }

    .menu {
        visibility: hidden;
        height: 0;
        overflow: hidden;
    }

    .navbar {
        height: 0;
        overflow: hidden;
    }
}

@media screen and (min-width: 769px) {
    .menu {
        column-gap: 40px;
    }
}

@media screen and (min-width: 1201px) {
    .menu {
        column-gap: 60px;
    }
}