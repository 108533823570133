.the-background {
    position: fixed;
    float: none;
    z-index: -666;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.the-background img {
    height: 100%;
    z-index: -665;
    position: relative;
}

.the-background img.background-first {
    z-index: -664;
}

.the-background img.background-second {
    z-index: -663;
}

.the-background img.background-third {
    z-index: -662;
}

.void-background {
    height: 100%;
    z-index: -649;
    position: relative;
    display: block;
}

.background-image-container-wrapper {
    height: 100%;
    width: 100%;
}

.background-image-container {
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    flex-wrap: nowrap;
}

.background-darkness {
    background: var(--cmx-base-color);
    height: 100%;
    width: 100%;
    z-index: -650;
    position: relative;
}