header {
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px;
    border-bottom: var(--cmx-container-border);
    background: var(--cmx-container-background-reverse);
    box-shadow: var(--cmx-container-box-shadow);
    margin-bottom: 30px;
}

header.container-vertical {
    row-gap: 0;
}

.comic-logo {
    max-width: 600px;
    height: 150px;
    overflow: hidden;
    margin-bottom: 10px;
}

.comic-logo-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    height: 100%;
    max-width: 100%;
}

.comic-logo-image img {
    width: 100%;
    max-height: 100%;
}

.loader-wrapper {
    height: 30px;
}

@media screen and (max-width: 768px) {
    header.container-vertical {
        row-gap: 0;
    }
}